import React, { useState, useEffect } from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import styles from "./Events.module.scss"; // Assuming you've created a CSS module for styling
import stylesBanner from "./BannerSmall.module.scss";
import {
  generateGradientTextStyle,
  generateTestimonialCardStyle,
} from "./util/theme";

SwiperCore.use([Autoplay, Pagination]);

const isValidImageUrl = (url) => {
  return url && url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null;
};

const Testimonials = ({ data, theme }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const baseStyle = generateTestimonialCardStyle(theme);
  const hoverStyle = baseStyle.hover;
  delete baseStyle.hover; // Remove hover from baseStyle

  const [visibleCount, setVisibleCount] = useState(data.visibilityLimit || 3);

  // Inside the Testimonials component
  const [screenWidth, setScreenWidth] = useState(1024); // default width

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenWidth(window.innerWidth);
      const handleResize = () => setScreenWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const displayCount = screenWidth <= 480 ? visibleCount : data.content.length;

  // Function to handle "Load More" click
  const loadMore = () => {
    setVisibleCount(data.content.length); // Show all items
  };

  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
    //   style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? styles[item.className] : ""} ${theme.style === "light" ? styles["text-black-color"] : ""}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        {data.useSlider ? (
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="feedback-content text-center">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  autoplay={{ delay: 2000 }}
                  speed={1200}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    800: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  {data.content.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                      <div className={`single-feedback-item ${styles["customCardStyleLight"]}`}>
                        {isValidImageUrl(testimonial.image) && (
                          <img
                            className="rounded-circle shadow-1-strong mb-4"
                            src={testimonial.image}
                            alt={testimonial.name}
                            loading="lazy"
                            style={{ width: "150px" }}
                          />
                        )}
                        <div className="row d-flex justify-content-center">
                          <div
                            className={
                              theme.style === "light"
                                ? styles.customCardBodyLight
                                : styles.customCardBody
                            }
                          >
                            <p
                              className={`${theme.style === "light" ? "text-dark" : "text-white"}`}
                              dangerouslySetInnerHTML={{
                                __html: testimonial.feedback,
                              }}
                            ></p>
                            <h5 className="mb-3">{testimonial.name}</h5>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        ) : (
          <div className={`${styles.testimonialGrid}`}>
            {data.content.slice(0, displayCount).map((testimonial, index) => (
              <div
                key={index}
                className={styles.testimonialCard}
                style={
                  hoveredCardIndex === index
                    ? { ...baseStyle, ...hoverStyle }
                    : baseStyle
                }
                onMouseEnter={() => setHoveredCardIndex(index)}
                onMouseLeave={() => setHoveredCardIndex(null)}
              >
                <div className={`${styles.testimonialContent}`}>
                  <div className={`${styles.testimonialGridRow}`}>
                    <div className={`${styles.testimonialImage}`}>
                      <img
                        src={testimonial.image}
                        loading="lazy"
                        alt={testimonial.name}
                        className={`${styles.imageFitCover}`}
                      />
                    </div>
                    <div className={`${styles.testimonialDetails}`}>
                      <div className={`${styles.testimonialNameWrapper}`}>
                        <div
                          className={`${styles.testimonialName}`}
                          style={{
                            color: theme.style === "light" ? "#000" : undefined,
                          }}
                        >
                          {testimonial.name}
                        </div>
                        <div className={`${styles.testimonialPostWrapper}`}>
                          <div
                            className={`${styles.testimonialPost}`}
                            style={{
                              color:
                                theme.style === "light" ? "#555" : undefined,
                            }}
                          >
                            {testimonial.post}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      theme.style === "light"
                        ? styles.customCardBodyLight
                        : styles.customCardBody
                    }
                    style={{
                      color: theme.style === "light" ? "#666" : undefined,
                      marginTop: '20px',
                    }}
                    dangerouslySetInnerHTML={{ __html: testimonial.feedback }}
                  ></div>
                </div>
              </div>
            ))}
            {screenWidth <= 480 && data.content.length > visibleCount && (
              <div className={`text-center`}>
                <button
                  onClick={loadMore}
                  className={`${styles.loadMoreButton} ${styles.widgetButton} ${theme.style === "light" ? "text-black" : "text-white"}`}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
